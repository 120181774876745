import { Button, Col, Divider, Row } from "antd";
import { PageLayout } from "../components/layouts/pageLayout";

export const GeneralPage = () => {
  return (
    <PageLayout>
      <Row
        gutter={[18, 18]}
        style={{
          maxWidth: "1200px",
          margin: "20px",
        }}
      >
        <Col span={24} style={{ marginTop: "25px" }}>
          <h1>Products</h1>
          <Divider></Divider>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <h2>{"Out of sight, peace of mind"}</h2>
          <p>
            {
              "Incloud is the cloud software that manages movement in the system. It is designed to derisk and simplify everything from system design, through installation, operations, monitoring and growth."
            }
          </p>
        </Col>
        <Col xs={24} sm={24} md={12}>
          <video
            autoPlay
            loop
            muted
            style={{
              borderRadius: "1.5rem",
              borderStyle: "hidden",
              width: "95%",
              objectFit: "cover",
            }}
          >
            <source
              src="https://instock.com/en/videos/hero.mp4"
              type="video/mp4"
            />
          </video>
        </Col>
        <Divider></Divider>

        <Col xs={24} sm={24} md={12}>
          <img
            alt="screenshots"
            width={"100%"}
            src="https://instock.com/en/images/system/incloud-overview_hub96a750b00f0c33391f2cda6c277d1ab_1503669_1500x0_resize_q75_h2_box_3.webp"
          />
        </Col>
        <Col xs={24} sm={24} md={12}>
          <p>
            {
              "Incloud is the cloud software that manages movement in the system. It is designed to derisk and simplify everything from system design, through installation, operations, monitoring and growth."
            }
          </p>
        </Col>
        <Divider></Divider>
        <Col span={24} style={{ textAlign: "center", marginBottom: "15px" }}>
          <p>{"Simulate your fulfillment automation!"}</p>
          <Button shape="round"> Book a Call</Button>
        </Col>
      </Row>
    </PageLayout>
  );
};
