import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// import { NavBar } from "./components/navigation/navBar";
import { LandingPage } from "./pages/LandingPage";
import ScrollToTop from "./utils/ScrollToTop";
import { NotFound } from "./utils/notFound";
import { ContactUs } from "./pages/ContactUs";

import { GeneralPage } from "./pages/GeneralPage";
import { ComingSoon } from "./pages/ComingSoon";
import { Chat } from "./pages/Chat";
import { Assets } from "./pages/Assets";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route exact path="/" element={<ComingSoon />} />
        <Route exact path="/site" element={<LandingPage />} />
        <Route exact path="/contact-us" element={<ContactUs />} />
        <Route exact path="/products" element={<GeneralPage />} />
        {/* Utils links */}
        <Route exact path="/assets" element={<Assets />} />
        <Route exact path="/chat" element={<Chat />} />
        <Route exact path="/*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
