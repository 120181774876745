import { Button, Card, Col, Row } from "antd";
import finLogo from "../images/fin-logo-04.webp";
export const ComingSoon = () => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Row
        gutter={[18, 18]}
        style={{ textAlign: "center", margin: "4px", maxWidth: "500px" }}
      >
        <Col span={24}>
          <img
            alt="logo"
            src={finLogo}
            style={{ width: "100%", maxWidth: "500px" }}
          />
        </Col>

        <Col style={{ textAlign: "center" }}>
          <Card>
            <p>
              {
                "Fintralogistics is a GTM consultancy service for multiple complimentary automation solutions in the area of Intralogistics, Ai & Robotics for New Business Exploration and End User Evaluation. As a business we pride ourselves in being able to offer Business Development, Data Analysis, Account Management and Commercial Strategy to new or smaller organisations looking to penetrate the UK, EU or US markets."
              }
            </p>
          </Card>
        </Col>

        <Col
          span={24}
          style={{ textAlign: "center", fontFamily: "sundayAfternoon" }}
        >
          <Button
            href="https://www.linkedin.com/company/fintralogisticsuk"
            rel="noreferrer"
            target="_blank"
            style={{
              color: "#1F7DC1",
              fontFamily: "sundayAfternoon",
            }}
          >
            {" "}
            Linkedin
          </Button>
          <Button
            href="https://calendly.com/fintralogistics/30min"
            rel="noreferrer"
            target="_blank"
            style={{
              color: "#1F7DC1",
              marginLeft: "4px",
              fontFamily: "sundayAfternoon",
            }}
          >
            {" "}
            Schdule a Call
          </Button>
        </Col>
      </Row>
    </div>
  );
};
