import { Col, Divider, Row, Form, Input, Modal, Button } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { PageLayout } from "../components/layouts/pageLayout";

export const ContactUs = () => {
  const [form] = Form.useForm();

  const postDiscordMessage = (values) => {
    return axios
      .post(".netlify/functions/postDiscordMessage", {
        values,
      })
      .then((response) => {
        console.log(response);
      })
      .catch(function error(error) {
        const errorMessage = error.response.data;
        console.log(errorMessage);
      });
  };

  const onFinish = (values) => {
    form.validateFields().then((values) => {
      const success = () => {
        Modal.success({
          content:
            "Thanks for getting in touch with us... Will get in touch ASAP!!",
        });
      };
      postDiscordMessage(values);
      success();
      form.resetFields();
    });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <PageLayout>
      <Row gutter={[18, 18]} style={{ maxWidth: "1000px", margin: "5px" }}>
        <Col span={24} style={{ marginTop: "25px" }}>
          <h1 className="header-contact-us">Contact Us</h1>
          <Divider />
        </Col>
        <Col xs={24} sm={24} md={12}>
          <h2 className="header-contact-form">
            <strong>Seeing is believing!</strong>
          </h2>
          <div className="contact-form-list">
            <ol className="contact-form-list">
              <li>{"Fill in the contact form"}</li>
              <li>{"Together we can quickly configure your system"}</li>
              <li>
                {"See your Instock digital twin and fulfillment simulation"}
              </li>
              <li>{"Receive your RaaS quote"}</li>
              <li>{"Schedule your installation"}</li>
            </ol>
          </div>
          <p>{"Other inquiries — info@instock.com"}</p>
        </Col>

        <Col
          xs={24}
          sm={24}
          md={12}
          style={{
            backgroundColor: "#50d7e8",
            padding: "25px",
            borderRadius: "1.5rem",
            borderStyle: "hidden",
          }}
        >
          <Form
            name="basic"
            form={form}
            onFinish={onFinish}
            layout="vertical"
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Row
              gutter={[18, 18]}
              style={{ padding: "15px" }}
              className="contact-form"
            >
              <Col span={24}>
                <Form.Item
                  style={{ margin: "5px" }}
                  label="Name"
                  name="hostname"
                  rules={[
                    { required: true, message: "Please input your name" },
                  ]}
                >
                  <Input placeholder={"Your Name"} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  style={{ margin: "5px" }}
                  label="Company Email"
                  name="companyEmail"
                  rules={[
                    { required: true, message: "Your Company Email Address" },
                  ]}
                >
                  <Input placeholder={"Your Company Email Address"} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  style={{ margin: "5px" }}
                  label="Company"
                  name="companyName"
                  rules={[{ required: true, message: "Your Company Name" }]}
                >
                  <Input placeholder={"Your Company"} />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  style={{ margin: "5px" }}
                  label="Enquiry"
                  name="enquiry"
                  rules={[{ required: true, message: "Your Company Name" }]}
                >
                  <TextArea
                    placeholder="send us details of your enquiry"
                    rows={3}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item style={{ margin: "5px" }}>
                  <Button
                    shape="round"
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: "black" }}
                  >
                    Send Inquiry
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Col>
        <Divider></Divider>
        <Col span={24} style={{ textAlign: "center" }}>
          <p>{"Simulate your fulfillment automation!"}</p>
          <Button shape="round">Schedule a Call</Button>
        </Col>
      </Row>
    </PageLayout>
  );
};
