import { useEffect } from "react";

export const Chat = () => {
  useEffect(() => {
    // const timeout = setTimeout(() => {
    //   // 👇️ redirects to an external URL
    //   window.location.replace("https://codefrontend.com");
    // }, 1000);

    // return () => clearTimeout(timeout);
    window.location.replace("https://calendly.com/fintralogistics/30min");
  }, []);

  return <>Taking you to my Calendar...</>;
};
